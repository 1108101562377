import React,{ useEffect} from 'react'
import ReactGa from 'react-ga'
import {GOOGLE_ANALYTIC} from '@env'
import './src/styles/App.css'
import Routes from './src/routes'


function App (){
	
	useEffect(()=>{
		ReactGa.initialize(GOOGLE_ANALYTIC)
		ReactGa.pageview(window.location.pathname + window.location.search)
	},[])

	return (
		< Routes />
	)
}


export default App
