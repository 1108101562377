// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/Images/solar_panel_background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bodyanalytic {\r\n  /* flex: 1; */\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n.main {\r\n  display: flex;\r\n  flex-flow: column;\r\n  background-color: #000e44;\r\n  width: 95%;\r\n  height: 15%;\r\n  margin-top: 2.5%;\r\n  margin-bottom: 2.5%;\r\n  margin-left: 2.5%;\r\n  margin-right: 2.5%;\r\n  border-radius: 10px;\r\n}\r\n.ant-layout-sider-trigger {\r\n  display: none;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
