import React, { Component } from "react";
import ParseccTitle from "../common/ParseccTitle/ParseccTitle";
import Image from "../../../assets/Images/Broken.png";
import "./Error.css";


class Error404 extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className='bodyerror'>
        <div style={{ marginTop: "1%" }} className='nonTransparentContainer'>
          <ParseccTitle></ParseccTitle>
          <div style={{ flex: 2, backgroundColor: "white" }}>
            <div>
              <h2
                style={{
                  fontSize: "3.4rem",
                  color: "#000e44",
                  marginTop: "3%",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                }}
              >
                404
              </h2>
            </div>

            <div
              style={{
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "28rem", marginTop: "-5%" }}
                src={Image}
                alt=''
              />
            </div>
            <div
              style={{
                marginLeft: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#000e44",
                  fontFamily: "Montserrat",
                }}
              >
                This site cannot be reached.
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#000e44",
                  marginTop: "-1%",
                  fontFamily: "Montserrat",
                }}
              >
                www.lumenaenergy.com's server IP cannot be Found
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error404;
