import React, { Component } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import LumenaLogo from '../common/LumenaEnergyLogo/LumenaEnergy'
import ReCAPTCHA from 'react-google-recaptcha'
import AuthService from '../../Services/AuthService'
import { CAPTCHA_SITE_KEY } from '@env'
import './SignIn.css'
import { Input, Row, Col, message, Form } from 'antd'
import 'antd/dist/antd.css'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'

class SignIn extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            isVerified: false,
        }
    }

    onEmailChange(event) {
        this.setState({ ...this.state, email: event.target.value })
    }
    onPasswordChange(event) {
        this.setState({ ...this.state, password: event.target.value })
    }

    onReCAPTCHAChange(event) {
        this.setState({ ...this.state, isVerified: !this.state.isVerified })
    }

    onLogin(values) {
		const {email, password} = values

		if(this.state.isVerified){
			AuthService.login(email, password).then(
				(response) => {
					if (response.status === 200) {
						response.json().then((data) => {
							localStorage.setItem(
								'user',
								JSON.stringify(data.accessToken)
							)
							this.props.history.push('/dashboard')
						})
					} else if (response.status === 401) {
						this.props.history.push('/contact_support')
					} else if (response.status === 400) {
						message.error('invalid email or password')
					}else if(response.status === 404){
						this.props.history.push('/internal')
					}
				},
				(error) => {
					console.log('could no connect')
					// const resMessage =
					// 	(error.response &&
					// 		error.response.data &&
					// 		error.response.data.message) ||
					// 	error.message ||
					// 	error.toString()
				}
			)
		}
	}

    render() {
        return (
            <div className="body">
				<div style={{ marginTop: '2rem' }} className="transparentContainer">
                    <div className="TitleContainerStyle">
                        <ParseccTitle></ParseccTitle>
                    </div>
                    <h2 className="subTitleStyle">SIGN IN</h2>
                    <Form onFinish = {this.onLogin.bind(this)}>
                        <Row justify="center" className="align-items-stretch h-100">
                            <Col style={{
                                    backgroundColor: 'white',
                                    padding: '1%',
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                                xs={23}
                                sm={23}
                                md={13}
                                lg={8}>
                                <Form.Item name = 'email'>
                                    <Input style={{
                                            width: '100%',
                                            borderRadius: 20,
                                            fontFamily: 'Montserrat',
                                            marginBottom: '2%',
											}}
                                        size="large"
                                        placeholder="Email"
                                        prefix={<UserOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item name = 'password'>
                                    <Input style={{
                                            width: '100%',
                                            borderRadius: 20,
                                            fontFamily: 'Montserrat',
                                            marginBottom: '4%',
											}}
                                        size="large"
                                        type="password"
                                        placeholder="Password"
                                        prefix={<UnlockOutlined />}
                                    />
                                </Form.Item>
                                <br />
                                <div className="centerly">
                                    <ReCAPTCHA className="g-recaptcha"
                                        sitekey={CAPTCHA_SITE_KEY}
                                        onChange={this.onReCAPTCHAChange.bind(this)}/>
                                </div>
                                <br />

                                <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                    <button className="blueButton" type = 'submit'>
                                        Continue
                                    </button>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    }}>
                                    <a href="/forgot_password">Forgot Password?</a>
                                </div>
                                <br />
                            </Col>
                        </Row>
                    </Form>
                    <div className="bottomContainer bottomAlignment">
                        <h6 className="bottomTextStyle">
                            All content copyright Lumena Energy 2021
                        </h6>
                        <LumenaLogo />
                    </div>
                </div>
            </div>
        )
    }
}

export default SignIn
