import React, { Component } from "react";
import ParseccTitle from "../common/ParseccTitle/ParseccTitle";
import "./Error.css";

// import "./graic.css"

class Error500 extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="bodyerror">
        <div style={{ marginTop: "1%" }} className="nonTransparentContainer">
          <ParseccTitle></ParseccTitle>
          <div style={{ flex: 2, backgroundColor: "white" }}>
            <div>
              <h2
                style={{
                  fontSize: "3.2rem",
                  // marginLeft: "15%",
                  color: "#000e44",
                  marginTop: "3%",
                  fontFamily: "Montserrat",
                  textAlign:"center"
                }}
              >
                500 Internal Server Error
              </h2>
            </div>

            <h3
              style={{
                fontSize: "2.2rem",
                textAlign: "center",
                marginTop: "10%",
                fontFamily: "Montserrat",
              }}
            >
              There is a problem with the resource you are looking for and cannot be
              displayed.
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Error500;
