import React, { Component } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import { Input, Form, message } from 'antd'
import './email.css'
import UserService from '../../Services/UserService'

class Eamil extends Component {
    constructor() {
        super()
    }

    onEmailPasswordLink(values) {
		const {email} = values
		UserService.emailPasswordResetLink(email).then((response) =>{
			if(response.status === 200){
				message.success('Please check your email. Password reset link was emailed.')
			}else{
				message.error('email was not found')
			}
		})
	}

    render() {
        return (
            <div className="bodyemail">
                <div
                    style={{ marginTop: '1%' }}
                    className="nonTransparentContainer"
                >
                    <ParseccTitle></ParseccTitle>
                    <div style={{ flex: 2, backgroundColor: 'white' }}>
                        <div>
                            <h2 style={{
                                fontSize: '18px',
                                textAlign: 'center',
                                color: '#000e44',
                                marginTop: '5%',
                                fontFamily: 'Montserrat'}}>
                                Enter Email to Reset Password
                            </h2>
                        </div>
                        <Form onFinish = {this.onEmailPasswordLink.bind(this)}>
                            <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
								<Form.Item name="email" style = {{width: '35%'}}>
                                    <Input
                                        style={{
                                            fontFamily: 'Montserrat',
                                            borderRadius: 20,
                                        }}
										type = 'email'
                                        size="large"
                                        placeholder="Enter Email"/>
                                </Form.Item>
                                <button style={{ marginTop: '1%' }} className="blueButton" type="submit">
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Eamil
