// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/Solar Farm Login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bodyhome {\r\n    flex: 1;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n\r\n.captionStyle {\r\n    color: #80b0ff;\r\n    font-family: 'Montserrat';\r\n    margin-top: 0px;\r\n    font-size: 1.2rem;\r\n}\r\n.wholeTitleStyle {\r\n    flex-direction: column;\r\n    margin-top: 2%;\r\n}\r\n.logoAndTitleStyle {\r\n    display: flex;\r\n}\r\n\r\n.signInContainer {\r\n    display: flex;\r\n}\r\n.signInTextStyle {\r\n    color: #80b0ff;\r\n    font-size: 1.2rem;\r\n    font-family: 'Montserrat';\r\n    text-align: center;\r\n}\r\n\r\n.midStyle {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex: 1 1 auto;\r\n}\r\n.btnsStyle {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n.textStyle {\r\n    color: #80b0ff;\r\n    font-family: 'Montserrat';\r\n    font-size: 1.2rem;\r\n    margin-top: 1%;\r\n}\r\n.Style {\r\n    color: #80b0ff;\r\n    font-family: 'Montserrat';\r\n    font-size: 1.2rem;\r\n    margin-top: 5%;\r\n}\r\n.hypertextStyle {\r\n    color: white;\r\n    font-family: 'Montserrat';\r\n    font-size: 1.2rem;\r\n    margin-bottom: 0px;\r\n    margin-top: 1rem;\r\n    margin-left: 0.5rem;\r\n    margin-right: 0.5rem;\r\n}\r\n.hypertextAndTextStyle {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.lumenaEnergyStyle {\r\n    color: white;\r\n    font-size: 2rem;\r\n    font-family: 'Montserrat';\r\n    margin-bottom: 0px;\r\n    margin-top: 0px;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
