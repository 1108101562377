import authHeader from './AuthHeader'
import {SERVER_URL} from '@env'

const featureDevices = async()=>{
	try{
		let header = authHeader()

		return await fetch(`${SERVER_URL}/device/featureset`,{
			method: 'GET',
			mode:'cors',
			credentials:'same-origin',
			headers: header
		})
	}catch (err){
		console.log(err)
	}
}

const emailPasswordResetLink = async(email)=>{
	try{
		return await fetch(`${SERVER_URL}/password_reset_link/${email}`,{
			method: 'GET',
			mode: 'cors',
			credentials:'same-origin'
		})
	}catch (err){
		console.log(err)
	}
}

const passwordReset = async (user_id,password)=>{
	try {
		return await fetch(`${SERVER_URL}/password_reset/${user_id}`,{
			headers: {
				'content-Type': 'application/json'
			},
			method: 'PATCH',
			mode: 'cors',
			credentials: 'same-origin',
			body:JSON.stringify({
				password:password
			})
		})
	} catch (err){
		console.log(err)
	}
}

const makePayment = async(totalAmount, token)=>{
	return await fetch(`${SERVER_URL}/payment`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'same-origin',
		headers: header,
		body:JSON.stringify({
			amount:totalAmount,
			id:token
		})
	})
}

export default {
	makePayment,
	emailPasswordResetLink,
	passwordReset,
	featureDevices
}