import React, { Component } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import './SignUp.css'
import LumenaLogo from '../common/LumenaEnergyLogo/LumenaEnergy'
import AuthService from '../../Services/AuthService'
import { Input, Row, Col, Checkbox, message, Form } from 'antd'
import {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    UsergroupAddOutlined,
    UnlockOutlined,
} from '@ant-design/icons'
import 'antd/dist/antd.css'
import { PasswordInput } from 'antd-password-input-strength'

class SignUp extends Component {
    constructor() {
        super()
        this.state = {
            full_name: '',
            email: '',
            password: '',
            company_name: '',
            phone_number: '',
            account_type: 'customer',
        }
    }

    onChange(e) {
		// console.log(e)
        // console.log(`checked = ${e.target.checked}`)
		if(e.target.checked){
			this.setState({...this.state, account_type: 'enterprise'})
		}else{
			this.setState({...this.state, account_type: 'customer'})
		}
    }

    onFullNameChange(event) {
        this.setState({ ...this.state, full_name: event.target.value })
    }
    onEmailChange(event) {
        this.setState({ ...this.state, email: event.target.value })
    }
    onPasswordChange(event) {
        this.setState({ ...this.state, password: event.target.value })
    }
    onCompanyNameChange(event) {
        this.setState({ ...this.state, company_name: event.target.value })
    }

    onPhoneNumberChange(event) {
        this.setState({ ...this.state, phone_number: event.target.value })
    }

    onAccountTypeChange(event) {
        const value = event.target.checked ? 'enterprise' : 'customer'
        this.setState({ ...this.state, account_type: value })
    }

    createAccount(event) {
        event.preventDefault()

        const {
            full_name,
            company_name,
            email,
            password,
            phone_number,
            account_type,
        } = this.state
        AuthService.register(
            full_name,
            company_name,
            email,
            password,
            phone_number,
            account_type
        ).then(
            (response) => {
                if (response.status === 200) {
					response.json().then(data =>{
						message.success(data.msg)
					})
                }else{
					response.json().then(data =>{
						message.error(data.msg)
					})
				}
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
            }
        )
    }

	onCreateAccount(values){
		const {
			full_name,
            company_name,
            email,
            password,
            phone_number,
		} = values
		const {
			account_type
		} = this.state

		AuthService.register(
            full_name,
            company_name,
            email,
            password,
            phone_number,
            account_type
        ).then(
            (response) => {
                if (response.status === 200) {
					response.json().then(data =>{
						message.success(data.msg)
					})
                }else{
					response.json().then(data =>{
						message.error(data.msg)
					})
				}
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
            }
        )
	}

    render() {
        return (
            <div className="body">
                <div style={{ marginTop: '2rem' }} className="transparentContainer">
                    <div className="TitleContainerStyle">
                        <ParseccTitle />
                    </div>
                    <div>
                        <h2 className="subTitleStyle">CREATE ACCOUNT</h2>
						<Form onFinish = {this.onCreateAccount.bind(this)}>
                        <Row justify="center" className="align-items-stretch h-100">
                            <Col style={{
                                    backgroundColor: 'white',
                                    padding: '1.5%',
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                                xs={23}
                                sm={23}
                                md={13}
                                lg={8}>
								<div style={{ marginBottom: '3%' }}>
									<Form.Item name = 'full_name'>
										<Input style={{ 
												width: '100%',
												borderRadius: 20,
												fontFamily: 'Montserrat',
												}}
												size="large"
												placeholder="Name"
												prefix={<UserOutlined />}/>
									</Form.Item>
                                </div>
                                <div style={{ marginBottom: '2%' }}>
									<Form.Item name = 'phone_number'>
										<Input style={{
												width: '100%',
												borderRadius: 20,
												fontFamily: 'Montserrat',
												}}
												size="large"
												placeholder="Phone number"
												prefix={<PhoneOutlined />}/>
									</Form.Item>
                                </div>
                                <div style={{ marginBottom: '2%' }}>
									<Form.Item name = 'company_name'>
										<Input style={{
												width: '100%',
												borderRadius: 20,
												fontFamily: 'Montserrat',
												}}
												size="large"
												placeholder="Company name"
												prefix={<UsergroupAddOutlined />}/>
									</Form.Item>
                                </div>
                                <div style={{ marginBottom: '2%' }}>
									<Form.Item name = 'email'>
										<Input style={{
												width: '100%',
												borderRadius: 20,
												fontFamily: 'Montserrat',
												}}
												size="large"
												placeholder="Email"
												prefix={<MailOutlined />}/>
									</Form.Item>
                                </div>
                                <div style={{ marginBottom: '2%' }}>
									<Form.Item name = 'password'>
										<PasswordInput style={{
														borderRadius: 20,
														fontFamily: 'Montserrat',
														}}
														placeholder="Password"
														size="large"
														prefix={<UnlockOutlined />}/>
									</Form.Item>
                                </div>
								<Form.Item name = 'isEnterprise' valuePropName="checked">
									<Checkbox style={{
												fontFamily: 'Montserrat',
												marginLeft: '1rem',
												}}
												onChange={this.onChange.bind(this)} >
										Is this an enterprise account?
									</Checkbox>
								</Form.Item>
                                <br />
                                <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
									}}>
                                    <button className="blueButton" type="submit">
                                        CONTINUE
                                    </button>
                                </div>
                                <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                    <a className="termAndConditionsStyle">
                                        {' '}
                                        Terms & Conditions
                                    </a>
                                </div>
                            </Col>
                        </Row>
						</Form>
                    </div>
                    <div className="bottomContainer bottomAlignment">
                        <h6 className="bottomTextStyle">
                            All content copyright Lumena Energy 2021
                        </h6>
                        <LumenaLogo />
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp
