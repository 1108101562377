import React from 'react'
import logo from '../../../../assets/Images/Lumena Energy Icon and Text white dark background logo tm.png'
import './LumenaEnergy.css'

const LumenaLogo = () =>{
	return (

		<div className = 'lumenaLogoHome'>
			<p className = 'poweredByStyle'>Powered by</p>
			<img alt="" src={logo} className = 'lumenalogoStyle'/>
		</div>
		
	)
}

export default LumenaLogo