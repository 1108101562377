import React, { useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import UserService from '../../../Services/UserService'
import { message } from 'antd'
import './style.css'
import 'antd/dist/antd.css'

import batteryIcon from '../../../../assets/Images/thunderbolt_icon.png'

function Map({history}){

	const MapEl = useRef(null)
    const [featurelayer, setFeatureLayer] = useState()
    const [tempmap, setTempMap] = useState(null)
    const [graphics, setGraphics] = useState([])
    const [previousGraphics, setPreviousGraphics] = useState([])

	const batteryInfoAction = {
		title: "Battery Info",
		id: "battery-info",
		image: batteryIcon
	}

	// format for the popup template for arcgis
	var template = {
        title: 'property {address}',
        content: [
            {
                type: 'fields',
                fieldInfos: [
                    {
                        fieldName: 'pv_input',
                        label: 'PV Input (kW)',
                        isEditable: true,
                        stringFieldOption: 'text-box',
                    },
                    {
                        fieldName: 'ac_load',
                        label: 'AC Load (W)',
                        isEditable: true,
                        stringFieldOption: 'text-box',
                    },
                    {
                        fieldName: 'data_usage',
                        label: 'Data Usage (kB)',
                        isEditable: true,
                        stringFieldOption: 'text-box',
                    },
                    {
                        fieldName: 'current',
                        label: 'Current (A)',
                        isEditable: true,
                        stringFieldOption: 'text-box',
                    },
                ],
            },
        ],
		actions: [batteryInfoAction]
    }

	// define the different fields of each node
    const fields = [
        {
            name: 'max_current',
            alias: 'Max current',
            type: 'double',
        },
        {
            name: 'max_pv_input',
            alias: 'Max PV Input',
            type: 'double',
        },
        {
            name: 'max_ac_load',
            alias: 'Max AC Load',
            type: 'double',
        },
        {
            name: 'pv_input',
            alias: 'PV Input',
            type: 'double',
        },
        {
            name: 'ac_load',
            alias: 'AC Load',
            type: 'double',
        },
        {
            name: 'current',
            alias: 'Current',
            type: 'double',
        },
        {
            name: 'data_usage',
            alias: 'Data Usage',
            type: 'double',
        },
        {
            name: 'address',
            alias: 'Address',
            type: 'string',
            length: 255,
        },
    ]

	
	// redirect when clicked on battery Info Icon
	function batteryInfoRedirect(){
		history.push('/analytics')
	}

	useEffect(() => {
        let view
        loadModules(
            ['esri/views/MapView', 'esri/WebMap', 'esri/layers/FeatureLayer'],
            { css: true }
        ).then(([MapView, WebMap, FeatureLayer]) => {
            const webmap = new WebMap({
                basemap: 'topo-vector',
            })
            view = new MapView({
                map: webmap,
                center: [-100, 40],
                zoom: 5,
                // use ref as a container
                container: MapEl.current,
            })

            setTempMap(webmap)
            UserService.featureDevices().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
						
                        setGraphics(data)
                        setPreviousGraphics(data)
                        const featureLayer = new FeatureLayer({
                            objectIdField: 'ObjectID',
                            fields: fields,
                            geometryType: 'point',
                            popupTemplate: template,
                            source: data
                        })
                        setFeatureLayer(featureLayer)
                        webmap.add(featureLayer)

						view.popup.on("trigger-action",(event)=>{
							if(event.action.id === 'battery-info'){
								batteryInfoRedirect()
							}
						})
                    }).catch(err=>{
						console.log(err)
					})
                }else if(response.status === 403){
					message.error('your session has expired. please sign in')
					history.push('/signIn')
				}else if(response.status === 401){
					message.error('please Sign In')
					history.push('/signIn')
				} else {
                    const featureLayer = new FeatureLayer({
                        objectIdField: 'ObjectID',
                        fields: fields,
                        geometryType: 'point',
                        popupTemplate: template,
                        source: graphics
                    })
                    setFeatureLayer(featureLayer)
                    webmap.add(featureLayer)
                }
            }).catch((error)=>{
				console.log(error)
			})
        })

        return () => {
            if (!!view) {
                view.destroy()
                view = null
            }
        }
    }, [])

    useEffect(() => {
        const live_data_fetch = setInterval(() => {
            UserService.featureDevices().then((response) => {
				
                if (response.status === 200) {
                    response.json().then((data) => {
						
                        setGraphics(data)
                    }).catch((err)=>{
						console.log(err)
					})
                }else if(response.status === 403){
					message.error('your session has expired. please sign in')
					history.push('/signIn')
				}else if(response.status === 401){
					message.error('please Sign In')
					history.push('/signIn')
				}
            }).catch(error =>{
				console.log(error)
			})
        }, 20 * 1000)
        return () => clearInterval(live_data_fetch)
    }, [])

    useEffect(() => {
        if (tempmap && featurelayer) {
            featurelayer
                .applyEdits({
                    addFeatures: graphics,
                    deleteFeatures: previousGraphics,
                })
                .then((result) => {
                    setPreviousGraphics(graphics)
                })
        }
    }, [graphics])

	return (
		<div style={{ height: '100%'}} ref={MapEl}></div>
	)

}

export default Map