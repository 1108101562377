// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/Solar Farm Login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bodyin {\r\n    flex: 1;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n.TitleContainerStyle {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n}\r\n\r\n.bottomAlignment {\r\n    justify-content: space-between;\r\n    margin-left: 1rem;\r\n    margin-right: 1rem;\r\n}\r\n.lumenaDiv {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.g-recaptcha {\r\n    width: 32rem;\r\n    border-radius: 0.4rem;\r\n    overflow: hidden;\r\n}\r\n.centerly {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
