import React from 'react'
import './ParseccTitle.css'

const ParseccTitle = ()=>{
	return (
		<div className = 'TitleContainerStyle'>
			<h1 className = 'Left_Title'>Par</h1>
			<h1 className = 'Right_Title'>secc</h1>
		</div>
	)
} 

export default ParseccTitle