import React, { Component, useState } from 'react'
import { Layout, Menu } from 'antd'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import './Analytic.css'
import Table from './Table'
import { Statistic, Row, Col, Button } from 'antd'
import 'antd/dist/antd.css'

const { Sider } = Layout


export default function Analytic({history}) {
    const [collapsed, setcollapsed] = useState(false)
	const [totalCustomers, setTotalCustomers] = useState(0)
	const [totalIncome, setTotalIncome] = useState(0.00)
	const [totalNodes, setTotalNodes] = useState(0)
	const [username, setUsername] = useState('')

	function onHomeButton (){
		history.push('/dashboard')
	}

    const toggleCollapsed = () => {
        setcollapsed(!collapsed)
    }
    return (
        <div className="bodyanalytic">
            <div className="main">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ marginLeft: '3%' }}>
                        <Button
                            style={{ backgroundColor: '#000e44fa' }}
                            onClick={toggleCollapsed}
                        >
                            <MenuOutlined style={{ color: 'white' }} />
                        </Button>
                    </div>
                    <div>
                        <ParseccTitle></ParseccTitle>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginRight: '5%',
                            alignItems: 'center',
                            justifyContent: 'between',
                        }}
                    >
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: ' 50%',
                                backgroundColor: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '5%',
                            }}
                        >
                            <img
                                style={{
                                    backgroundColor: 'white',
                                    marginLeft: '16%',
                                    borderRadius: 10,
                                }}
                                src={require('../../../assets/Images/account_icon.png')}
                            />
                        </div>
                        <div style={{ marginTop: '20%' }}>
                            <p
                                style={{
                                    fontFamily: 'Montserrat',
                                    color: 'white',
                                    marginBottom: '0px',
                                }}
                            >
                                {username}
                            </p>
                            <p
                                style={{
                                    fontFamily: 'Montserrat',
                                    color: 'white',
                                }}
                            >
                                Account setting
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 2, backgroundColor: 'white' }}>
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider
                            style={{ background: '#D9D4D4' }}
                            width={0}
                            collapsedWidth={90}
                            collapsible
                            collapsed={collapsed}
                        >
                            <div className="logo" />
                            <Menu
                                style={{ background: '#D9D4D4 ' }}
                                defaultSelectedKeys={['1']}
                                mode="inline"
                            >
                                <Menu.Item key="1">
                                    <img
                                        src={require('../../../assets/Images/search_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="2" onClick = {onHomeButton.bind(this)}>
                                    <img
                                        src={require('../../../assets/Images/house_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <img
                                        src={require('../../../assets/Images/thunderbolt_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <img
                                        src={require('../../../assets/Images/graph_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="5">
                                    <img
                                        src={require('../../../assets/Images/download_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="6">
                                    <img
                                        src={require('../../../assets/Images/document_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="7">
                                    <img
                                        src={require('../../../assets/Images/wifi_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="8">
                                    <img
                                        src={require('../../../assets/Images/users_icon.png')}
                                    />
                                </Menu.Item>
                                <Menu.Item key="9">
                                    <img
                                        src={require('../../../assets/Images/gear_icon.png')}
                                    />
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout
                            className="site-layout"
                            style={{ backgroundColor: 'white' }}
                        >
                            <div style={{ margin: '2%' }}>
                                <Row
                                    style={{
                                        justifyContent: 'space-evenly',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        display: 'flex',
                                        marginTop: '-1%',
                                    }}
                                    gutter={14}
                                >
                                    <Col
                                        style={{
                                            border: 'solid  #D9D4D4  1px',
                                            borderRadius: 10,
                                            // flexDirection: "row",
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        xs={18}
                                        sm={6}
                                        md={7}
                                        lg={7}
                                    >
                                        <Statistic
                                            style={{ fontFamily: 'Montserrat' }}
                                            title="TOTAL NODES"
                                            value={totalNodes}
                                        />
                                        <img
                                            style={{
                                                width: '3rem',
                                                height: '3rem',
                                            }}
                                            src={require('../../../assets/Images/node_icon.png')}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            border: 'solid  #D9D4D4  1px',
                                            width: '25%',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        xs={18}
                                        sm={6}
                                        md={7}
                                        lg={7}
                                    >
                                        <Statistic
                                            style={{ fontFamily: 'Montserrat' }}
                                            title="TOTAL INCOME"
                                            value={`$${totalIncome}`}
                                        />
                                        <img
                                            style={{
                                                width: '3rem',
                                                height: '3rem',
                                            }}
                                            src={require('../../../assets/Images/price_tag_icon.png')}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            border: 'solid #D9D4D4 1px',
                                            width: '25%',
                                            borderRadius: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        xs={18}
                                        sm={6}
                                        md={7}
                                        lg={7}
                                    >
                                        <Statistic
                                            style={{ fontFamily: 'Montserrat' }}
                                            title="TOTAL CUSTOMER"
                                            value={totalCustomers}
                                        />
                                        <img
                                            style={{
                                                width: '3rem',
                                                height: '2rem',
                                            }}
                                            src={require('../../../assets/Images/customers_icon.png')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row
                                    justify="center"
                                    className="align-items-stretch boxx"
                                >
                                    <Col xs={7} sm={6} md={12} lg={12}>
                                        <div style={{ margin: '2%' }}>
                                            <h2
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    color: '#000e44fa',
                                                }}
                                            >
                                                SYSTEM PERFORMANCE
                                            </h2>
                                            <div>
                                                <div>
                                                    {/* <img
                                                        style={{ width: '50%' }}
                                                        src={require('../../../assets/Images/stat.png')}
                                                        alt=""
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={20}
                                        sm={20}
                                        md={12}
                                        lg={12}
                                        className="container d-flex flex-column justify-content-center h-100"
                                    >
                                        <div style={{ marginTop: '1%' }}>
                                            <h2
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    color: '#000e44fa',
                                                }}
                                            >
                                                DATA MATRIX
                                            </h2>
                                            {/* <img
                                                style={{ width: '60%' }}
                                                src={require('../../../assets/Images/data.png')}
                                                alt=""
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ margin: '2%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <h2
                                        style={{
                                            fontFamily: 'Montserrat',
                                            color: '#000e44fa',
                                        }}
                                    >
                                        RECENT CUSTOMER
                                    </h2>
                                    <div>
                                        <img
                                            src={require('../../../assets/Images/big_gear_icon.png')}
                                        />
                                    </div>
                                </div>
                                <Table />
                            </div>
                        </Layout>
                    </Layout>
                </div>
            </div>
        </div>
    )
}
