import React from "react";
import { Table, Tag, Space, Button } from "antd";
import "antd/dist/antd.css";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    // key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Date",
    dataIndex: "Date",
    // key: "Date",
  },
  {
    title: "Phone number",
    dataIndex: "Phone",
    // key: "Phone",
  },
  {
    title: "Address",
    // key: "Address",
    dataIndex: "Address",
  },
  {
    title: "Further Details",
    // key: "action",
    render: (text, record) => (
      <div>
        <Button
          style={{
            fontFamily: "Montserrat",
            backgroundColor: "#3dafff",
            color: "white",
          }}
        >
          OPTIONS
        </Button>
        <Button
          style={{
            fontFamily: "Montserrat",
            backgroundColor: "#000e44fa",
            color: "white",
          }}
        >
          DETAILS
        </Button>
      </div>
    ),
  },
];
const data = [
  {
    key: "1",
    name: "Jerry Mattedi",
    Date: "13 Aug 2021  Joined",
    Phone: "773-567-2014 Phone number",
    Address: "Chicago, IL. Location ",
  },
  {
    key: "2",
    name: "Elianora Vasilov",
    Date: "13 Aug 2021  Joined",
    Phone: "847-127-2905 Phone number",
    Address: "Chicago, IL. Location ",
  },
  {
    key: "3",
    name: "Marcos Anguiano",
    Date: "13 Aug 2021  Joined",
    Phone: "708-567-5676 Phone number",
    Address: "Chicago, IL. Location ",
   
  },
  {
    key: "4",
    name: "Alvis Daen",
    Date: "13 Aug 2021  Joined",
    Phone: "773-543-2347 Phone number",
    Address: "Chicago, IL. Location ",
  },
];

function Tableform() {
  return (
    <div style={{ border: "solid #D9D4D4 1px", overflowX: "scroll" }}>
      <Table
        style={{ fontFamily: "Montserrat" }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
}

export default Tableform;
