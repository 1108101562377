import React, { Component } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import './support.css'

class ContactSupport extends Component {
    constructor() {
        super()
    }

    render() {
        return (
            <div className="bodysupport">
                <div style={{ marginTop: '1%' }} className="nonTransparentContainer">
                    <ParseccTitle></ParseccTitle>
                    <div style={{ flex: 2, backgroundColor: 'white' }}>
                        <div>
                            <h2
                                style={{
                                    fontSize: '35px',
                                    marginLeft: '20%',
                                    color: '#000e44',
                                    marginTop: '2%',
                                    fontFamily: 'Montserrat',
                                }}>
                                You have exceeded the amount of login attempts.
                            </h2>
                        </div>

                        <h3
                            style={{
                                fontSize: '25px',
                                textAlign: 'center',
                                marginTop: '10%',
                                fontFamily: 'Montserrat',
                            }}>
                            Please contact an administrator
                        </h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactSupport
