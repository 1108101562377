import React, { Component, useState } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import { message, Form } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import { UnlockOutlined } from '@ant-design/icons'
import { useParams } from 'react-router'
import UserService from '../../Services/UserService'
import 'antd/dist/antd.css'
import './password.css'


const resetPassword = ({history})=>{
	const {user_id} = useParams()
	function onResetPassword(values) {
        const { password1, password2 } = values
        if (password1 !== password2) {
            message.error('passwords do not match.')
        } else {
			UserService.passwordReset(user_id,password1).then(response =>{
				console.log(response)
				if(response.status === 200){
					message.success('password has been reset')
					history.push('/')
				}else{
					response.json().then(data =>{
						message.error(data.msg)
					})
				}
			})
        }
    }

	return (
		<div className="bodypassword">
			<div style={{ marginTop: '1%' }} className="nonTransparentContainer">
				<ParseccTitle></ParseccTitle>
				<div style={{ flex: 2, backgroundColor: 'white' }}>
					<div>
						<h2
							style={{
								fontSize: '18px',
								textAlign: 'center',
								color: '#000e44',
								marginTop: '5%',
								fontFamily: 'Montserrat'
							}}>
							Enter new Password
						</h2>
					</div>
					<Form onFinish={onResetPassword}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}>
							<Form.Item name="password1">
								<PasswordInput
									style={{
										borderRadius: 20,
										fontFamily: 'Montserrat',
									}}
									placeholder="Password"
									prefix={<UnlockOutlined />}
								/>
							</Form.Item>
							<h3
								style={{
									color: '#000e44',
									fontSize: '18px',
									fontFamily: 'Montserrat',
								}}
							>
								Confirm Password
							</h3>
							<Form.Item name="password2">
								<PasswordInput
									style={{
										borderRadius: 20,
										fontFamily: 'Montserrat',
									}}
									placeholder="Password"
									prefix={<UnlockOutlined />}
								/>
							</Form.Item>

							<button
								style={{ marginTop: '1%' }}
								className="blueButton"
								type="submit"
							>
								Submit
							</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default resetPassword
