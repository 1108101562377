import React, { Component } from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import './Lost.css'

function LostConnection() {
    return (
        <div className="bodylost">
            <div
                style={{ marginTop: '1%' }}
                className="nonTransparentContainer"
            >
                <ParseccTitle></ParseccTitle>
                <div style={{ flex: 2, backgroundColor: 'white' }}>
                    <div>
                        <h2
                            style={{
                                fontSize: '3.5rem',
                                textAlign: 'center',
                                color: '#000e44',
                                marginTop: '2%',
                                fontFamily: 'Montserrat',
                            }}
                        >
                            Error
                        </h2>
                    </div>

                    <h3
                        style={{
                            fontSize: '2.3rem',
                            textAlign: 'center',
                            marginTop: '10%',
                            fontFamily: 'Montserrat',
                        }}
                    >
                        Your internet Connection Was Lost
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default LostConnection
