// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../Users/Giovanny Roman/AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/Solar Farm Login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\r\n.body{\r\n\tflex: 1;\r\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\r\n}\r\n.termAndConditionsStyle{\r\n\tcolor:#00026e;\r\n} \r\n.bottomTextStyle{\r\n\tcolor:#41ba8e;\r\n\tmargin-left: 5px;\r\n\tmargin-right: 5px;\r\n} \r\n\r\n.bottomAlign{\r\n\tjustify-content: space-between;\r\n\tmargin-left: 1rem;\r\n\tmargin-right: 1rem;\r\n}\r\n.enterprisetext{\r\n\tfont-family: \"Montserrat\";\r\n\tfont-size: medium;\r\n\ttext-align: center;\r\n}\r\n.lumenaDiv{\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n}", ""]);
// Exports
module.exports = exports;
