import {SERVER_URL} from '@env'

const register = async(full_name, company_name, email, password, phone_number, accout_type)=>{
	try{
		return await fetch(`${SERVER_URL}/users`,{
			method:'POST',
			mode:'cors',
			credentials: 'same-origin',
			headers: {
				'content-Type': 'application/json'
			},
			body:JSON.stringify({
				full_name: full_name,
				company_name: company_name,
				email:email,
				password:password,
				phone_number: phone_number,
				account_type:accout_type
			})
		})
	} catch(err){
		console.log(err)
	}
}

const login = async (email,password) =>{
	try{
		return await fetch(`${SERVER_URL}/login`,{
			headers:{
				Accept:'application/json',
				'Content-Type': 'application/json',
			},
			credentials:'same-origin',
			method:'POST',
			mode:'cors',
			redirect:'follow',
			body: JSON.stringify({
				email:email,
				password:password
			})
		})
	} catch(err){
		console.log('could not reach login')
		console.log(err)
	}
}

const logout = () =>{
	localStorage.removeItem('user')
}

const getCurrentUser = () =>{
	return JSON.parse(localStorage.getItem('user'))
}

const verify_account = async(user_id)=>{
	try{
		return await fetch(`${SERVER_URL}/verify/${user_id}`,{
			method:'PATCH',
			mode:'cors',
			credentials: 'same-origin',
			headers: {
				'content-Type': 'application/json'
			}
		})
	} catch(err){
		console.log(err)
	}
}

export default {
	register,
	login,
	logout,
	getCurrentUser,
	verify_account
}