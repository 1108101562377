import { EnvironmentOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import './DashBoard.css'
import './SolarOption.css'
import 'antd/dist/antd.css'

const SolarOption = ()=>{
	return (
		<div className = 'solar_button_container'>
			<Button className="solar_button_style" type="primary" ghost>
				Solar Energy (Kwh)
			</Button>
			<Button className="solar_button_style" type="primary" ghost>
                Solar Generated
			</Button>
			<Button className="solar_button_style" type="primary" ghost>
                Solar Savings
            </Button>
			<Button className="solar_button_style" type="primary" ghost>
                Fault Passage Indicator
            </Button>
			<Button className="solar_button_style" type="primary" ghost>
                add a device
				<EnvironmentOutlined style={{ marginLeft: '5%' }}/>
            </Button>
		</div>
	)
}

export default SolarOption