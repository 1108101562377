import React from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import Map from '../common/Map/Map'

import ParseccLogo from '../common/ParseccLogo/Parsecmap'
import SolarOption from './SolarOptions'
import {message, Button} from 'antd'
import AuthService from '../../Services/AuthService'
import './DashBoard.css'
import 'antd/dist/antd.css'

export default function Dashboard({history}) {

	function onLogOut (){
		AuthService.logout()
		message.success('User was successfully logged out')
		history.push('/signIn')
	}

	function onAnalyticButton (){
		history.push('/analytics')
	}

	function onMyPropertiesButton (){
		history.push('/dashboard')
	}

    return (
        <div className="bodymap">
			<div style = {{ marginTop: '2rem' }} className = 'blue_container'>
				<div style={{ marginTop: '0%' }} className="TitleContainerStyle">
                    <ParseccLogo />
                    <ParseccTitle />
                </div>
				<div className = 'white_mid_Container'>
					<div className = 'logout_button_div'>
						<Button style = {{boxShadow: '2px 2px 2px 2px #888888'}}onClick = {onLogOut.bind(this)}>Log out</Button>
					</div>
					<div className = 'firstdiv'>
						<div style={{ marginTop: '-1.5rem' }}>
							<img src={require('../../../assets/Images/solar_monitoring_icon.png')} style = {{marginRight: '1rem'}} />
						</div>
						<h2 style={{ fontFamily: 'Montserrat', fontSize: '2.5rem',}}>
							Solar Monitoring
						</h2>
						<div style = {{marginLeft: '1rem'}}>
							<img className="lastimgdiv" src={require('../../../assets/Images/big_gear_icon.png')}/>
						</div>
					</div>
					{SolarOption()}
					<div className = 'map_div'>
						<div className = 'map_button'>
							<button className="red_button" onClick = {onMyPropertiesButton.bind(this)}>
								<img src={require('../../../assets/Images/properties_button_icon.png')}></img>
							</button>
							<button className="blue_button" onClick = {onAnalyticButton.bind(this)} >
								<img src={require('../../../assets/Images/analytics_button_icon.png')}
									></img>
							</button>
							<button className="purple_button">
								<img src={require('../../../assets/Images/cash_button_icon.png')}></img>
							</button>
						</div>
						<Map history = {history}/>
					</div>
				</div>
				<div className = 'white_bottomContainer'>
					<h6 className="bottomTextStyle">
                        All content copyright Lumena Energy 2021
                    </h6>
                    <h6 className="bottomTextStyle">
                        Engineered by Lumena Energy
                    </h6>
				</div>
			</div>
        </div>
    )
}
