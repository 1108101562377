import React from 'react'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import './DownForMaintenance.css'

function DownForMaintenance(){

	return (
		<div className = 'body_down_for_maintance'>
			<div style={{ marginTop: '2rem' }} className = 'blue_container'>
				<div style={{ marginTop: '0%' }} className="TitleContainerStyle">
					<ParseccTitle/>
				</div>
				<div className = 'white_midContainer'>
					<h2 style={{
                            fontSize: '3.5rem',
                            textAlign: 'center',
                            color: '#000e44',
                            marginTop: '2%',
                            fontFamily: 'Montserrat',
                        }}>
                        Down For Maintance
                    </h2>

					<h3 style={{
                        fontSize: '2.3rem',
                        textAlign: 'center',
                        marginTop: '10%',
                        fontFamily: 'Montserrat'}}>
                        Parsecc is currently down and will return by Time
                    </h3>

				</div>
			</div>
		</div>
	)
}

export default DownForMaintenance