import React from 'react';
import logo from '../../../../assets/Images/Parsecc Logo.png';
import './ParseccLogo.css';

const ParseccLogo = () => { 
	return (
		<img className="logoStyle" alt="" src={logo} />
	)
}

export default ParseccLogo;