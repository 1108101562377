export default function authHeader() {
	const accessToken = JSON.parse(localStorage.getItem('user'))
	

	if(accessToken){
		// return { 'x-access-token': user.accessToken }
		return {'auth-token': accessToken}
	}else{
		return {}
	}
}