import React, { Component, useEffect } from 'react'
import LumenaLogo from '../common/LumenaEnergyLogo/LumenaEnergy'
import ParseccTitle from '../common/ParseccTitle/ParseccTitle'
import ParseccLogo from '../common/ParseccLogo/ParseccLogo'
import AuthService from '../../Services/AuthService'
import { message } from 'antd'
import './Login.css'
import 'antd/dist/antd.css'
import { useParams } from 'react-router'

const Login = ({history}) =>{
	const {user_id} = useParams()
	function onClickSignIn() {
        history.push('/signIn')
    }
    function onClickSignUp() {
        history.push('/signUp')
    }

	useEffect(()=>{
		if(user_id){
			AuthService.verify_account(user_id).then(response=>{
				if(response.status === 200){
					message.success('account has been verified')
				}
			})
		}
	},[])

	return (
		<div className="bodyhome">
			<div style={{ marginTop: '2rem' }} className="transparentContainer">
				<div className="logoAndTitleStyle">
					<ParseccLogo></ParseccLogo>
					<div className="wholeTitleStyle">
						<ParseccTitle></ParseccTitle>
						<p className="captionStyle">
							Virtual Power Plant Management
						</p>
					</div>
				</div>
				<div className="midStyle">
					<p className="signInTextStyle" style = {{fontFamily: "Montserrat",}}>
						Already have a Parsecc Account?
					</p>
					<div
						style={{ justifyContent: 'space-between' }}
						className="btnsStyle"
					>
						<div>
							<button className="whiteButton" onClick={onClickSignUp}>
								SIGN UP
							</button>
						</div>
						<div>
							<button className="blueButton" onClick={onClickSignIn}>
								SIGN IN
							</button>
						</div>
					</div>
					<p className="textStyle" style ={{fontFamily: "Montserrat",}}>
						By using this software you agree to Parsecc's
					</p>
					<div className="hypertextAndTextStyle">
						<a className="hypertextStyle">Terms of Use </a>
						<p className="Style"> and </p>
						<a className="hypertextStyle" > Privacy Policy</a>
					</div>
				</div>
				<div className="bottomContainer bottomAlign">
					<h6 className="bottomTextStyle">
						All content copyright Lumena Energy 2021
					</h6>
					<LumenaLogo></LumenaLogo>
				</div>
			</div>
		</div>
	)
}

export default Login
