import React from 'react'
// import { Platform } from 'react-native'
// const isWeb = Platform.OS === 'web'
// import {Scene, Router, Actions} from 'react-router-native' 
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Login from './components/Login/Login'
import SignUp from './components/SignUp/SignUp'
import SignIn from './components/SignIn/SignIn'

import Dashboard from './components/DashBoard/DashBoard'
import Error404 from './components/Error/Error404'
import Error500 from './components/Error/Error500'
import LostConnection from './components/LostConnection/LostConnection'
import ContactSupport from './components/ContactSupport/ContactSupport'
import EmailPasswordReset from './components/EmailResetpassword/Email'
import PasswordReset from'./components/Password/Password'
import Analyics from './components/Analytics/Analytic'
import DownForMaintenance from './components/DownForMaintenance/DownForMaintenance'


const RouterComponent = () =>{
	return (
		<BrowserRouter>
			<Switch>
				<Route path = '/connection_lost' exact component = {LostConnection}></Route>
				<Route path = '/internal' exact component = {Error500}></Route>
				<Route path = '/404' exact component = {Error404}></Route>
				<Route path = '/analytics' exact component = {Analyics}></Route>
				<Route path ='/dashboard' exact component = {Dashboard}></Route>
				<Route path = '/contact_support' exact component = {ContactSupport}></Route>
				<Route path = '/reset_password/:user_id' exact component = {PasswordReset}></Route>
				<Route path = '/down_for_maintenance ' exact component = {DownForMaintenance}></Route>
				<Route path = '/forgot_password' exact component = {EmailPasswordReset}></Route>
				<Route path = '/signIn' exact component = {SignIn}></Route>
				<Route path = '/signUp' exact component = {SignUp}></Route>
				<Route path ='/:user_id' component = {Login}></Route>
				<Route path ='/' component = {Login}></Route>
			</Switch>
		</BrowserRouter>
	)
}

export default RouterComponent